import React from 'react'
import PageTypes from 'gatsby-theme-kn/src/components/page-types/_PageTypes'
import Blocks from 'gatsby-theme-kn/src/components/blocks/_Blocks'
import SbEditable from 'storyblok-react'

const loadStoryblokBridge = function (cb) {
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}`
  script.onload = cb
  document.getElementsByTagName('head')[0].appendChild(script)
}

const contentBlocks = (content) => (
  <div>
    {content.body &&
      content.body.map((block) =>
        React.createElement(Blocks(block.component), {
          key: block._uid,
          block: block,
          type: block.component,
        })
      )}
  </div>
)

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = { story: null }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory() {
    // console.log("Load Story")
    window.storyblok.get(
      {
        slug: window.storyblok.getParam('path'),
        version: 'draft',
        // resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ story: data.story })
      }
    )
  }

  initStoryblokEvents() {
    this.loadStory()

    let sb = window.storyblok

    sb.on(['change', 'published'], (payload) => {
      this.loadStory()
    })

    sb.on('input', (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return <div></div>
    }

    /* console.log(this.state.story) */

    let content = this.state.story.content
    // let navigation = this.state.globalNavigation.content

    const getLocale = (str) => {
      const arr = str.split('/')
      return arr[0]
    }

    return (
      <SbEditable content={content}>
        <div>
          {React.createElement(
            PageTypes(content.component),
            {
              key: content._uid,
              content: content,
              name: this.state.story.name,
              type: content.component,
              locale: getLocale(this.state.story.full_slug),
              full_slug: this.state.story.full_slug,
              childs: [],
            },
            contentBlocks(content)
          )}
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
